import AdUnitBidderValues from './adUnitBidderValues'

class BiddingTemplate {
  constructor() {
    this.bidderId = null
    this.status = true
    this.cpmAdjustmentEnabled = false
    this.adjustmentValue = null
    this.adjustmentReason = ''
    this.adUnitBidderValues = []
  }

  fromData(data) {
    this.bidderId = data.bidderId ? data.bidderId : null
    this.status = data.status ? data.status : true
    this.cpmAdjustmentEnabled = data.cpmAdjustmentEnabled ? data.cpmAdjustmentEnabled : false
    this.adjustmentValue = data.adjustmentValue ? data.adjustmentValue : null
    this.adjustmentReason = data.adjustmentReason ? data.adjustmentReason : ''
    this.adUnitBidderValues = data.adUnitBidderValues ? data.adUnitBidderValues.map(x => {
      const adUnitBidderValues = new AdUnitBidderValues()
      adUnitBidderValues.fromData(x)
      return adUnitBidderValues
    }) : null
  }
}

export default BiddingTemplate
